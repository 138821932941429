import { createAsyncThunk } from "@reduxjs/toolkit";
import { retrieveApi } from "api/retrive";

export const getPromotionList = createAsyncThunk(
  "retrive/getPromotionList",
  async (category_id: number, { rejectWithValue }) => {
    try {
      const result = await retrieveApi.getPromotionList(category_id);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
