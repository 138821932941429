import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import { GetBannerResponseType, GetPromotionListResponse } from "./type";

export const retrieveApi = {
  // async getPromotionList() {
  //   const { data } = await axiosRequest.post<GetPromotionListResponse>(
  //     "/",
  //     payloadHelper("PromotionList", null, null)
  //   );
  //   return data;
  // },
  async getPromotionList(category_id: number) {
    const { data } = await axiosRequest.post<GetPromotionListResponse>(
      "/",
      payloadHelper("CategoryPromotionList", null, { category_id })
    );
    return data;
  },
  async getBanner() {
    const { data } = await axiosRequest.post<GetBannerResponseType>(
      "/",
      payloadHelper("Banner", null, null)
    );
    return data;
  },
};
