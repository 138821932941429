import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const HeaderAuthStyle = {
  wrapper: css`
    display: flex;
    align-items: center;
  `,

  infoHeader: () => css`
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    flex-direction: column;

    h4 {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `,

  avatar: () => css`
    width: 50px;
    height: 50px;
    cursor: pointer;
  `,

  title: (visibleMenu: string, colorText: string) => css`
    width: 100%;
    text-align: right;
    color: ${visibleMenu ? theme.colors.lightText : colorText};
  `,

  points: (visibleMenu: string, colorText: string) => css`
    display: flex;
    align-items: center;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 13px;
      margin-left: 4px;
      color: ${visibleMenu ? "#2f0506" : colorText};
    }
  `,
  turnover: (visibleMenu: string, colorText: string) => css`
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: flex-start;
    margin-left: 12px;
    line-height: 1.2;
    color: ${visibleMenu ? theme.colors.lightText : colorText};

    span {
      font-weight: 400;
      text-transform: uppercase;
      font-size: 12px;
    }

    .amount {
      line-height: 1.5;
      display: flex;
      align-items: center;

      span {
        display: block;
        font-weight: 500;
        font-size: 12px;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
};
