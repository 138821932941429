/** @jsxImportSource @emotion/react */

import { Image } from "antd";
import iPlayLogo from "assets/Common/icons/iplay_logo 2.png";
import { PATH } from "contants/path";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ButtonTelegram from "./ButtonTelegram";
import { HeaderStyle } from "./index.style";
import LanguageSelect from "./LanguageSelect";

export const HeaderAffiliate = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { pathname = "" } = useLocation();

  const navigate = useNavigate();

  const [visibleMenu, setVisibleMenu] = useState<string>("");
  const [backgroundColor, setBackgroundColor] = useState<
    "white" | "transparent"
  >("transparent");

  const handleScroll = () => {
    if (window.scrollY < 15) {
      setBackgroundColor("transparent");
      return;
    }
    setBackgroundColor("white");
  };

  useEffect(() => {
    setBackgroundColor("transparent");
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event?.target as Node;
      if (!menuRef?.current?.contains(target)) {
        setVisibleMenu("");
      }
    };
    if (visibleMenu) {
      document.addEventListener("mouseover", handleClickOutside);
    } else {
      document.removeEventListener("mouseover", handleClickOutside);
    }
    return () => document.removeEventListener("mouseover", handleClickOutside);
  }, [visibleMenu]);

  return (
    <div ref={menuRef} css={HeaderStyle.index(backgroundColor)}>
      <div css={HeaderStyle.container}>
        <div css={HeaderStyle.wrapper}>
          <div css={HeaderStyle.heading} onClick={() => navigate(PATH.Home)}>
            <Image alt="img" src={iPlayLogo} preview={false} />
          </div>

          <div css={HeaderStyle.rows}>
            <ButtonTelegram />

            <LanguageSelect />
          </div>
        </div>
      </div>
    </div>
  );
};
